import { reactive, readonly } from 'vue'
import { EnumsDomStorage, FrontendErrorCode, MetadataKey } from '@/types/enums'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import appStore from '@/stores/app.store'
import apiService from '@/services/api.service'
import accountStore from '@/stores/account.store'
import ApiError from '@/types/ApiError'
import { trackEvent } from '@/services/gtm.service'

interface accountStoreStates {
  token: string | null // KeyCloak Token to Auth with
}

// Create store for account data from api-endpoint /account
const state = reactive({
  token: localStorage.getItem(EnumsDomStorage.AUTH_TOKEN) || null,
} as accountStoreStates)

/**
 * Checks on each Route-Change
 */
const checkRouteAuth = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  let idToken = null
  let accessToken = ''

  //## Check for bad Calendly links MPAD-1157
  if (to.query && to.query['assigned_to']) {
    console.warn('CalendlyLink found - removing:', to.query)
    return next({ query: {} })
  }

  //## Check Auth Redirect and tokens
  if (to.hash && !['#motivation'].includes(to.hash)) {
    // is redirect from Auth0

    const hash = to.hash.replace('#access_token', '?access_token')
    const urlParams = new URLSearchParams(hash)

    accessToken = urlParams.get('access_token') || ''
    idToken = urlParams.get('id_token') || ''

    // B2B using the auth0 token directly for the authentication with the backend, so no need for /auth (MPAD-1174)
    if (appStore.state.isB2b) {
      state.token = accessToken
      // load last url path | MPAD-643
      const lastUrl = sessionStorage.getItem(EnumsDomStorage.ORIGIN_URL_PATH)
      return next(lastUrl ?? to.path)
    }

    // get authentication bearer for B2C from Backend
    await apiService.auth.post(accessToken, idToken).then(
      (result) => {
        state.token = result.data.api_token
        localStorage.setItem(EnumsDomStorage.AUTH_TOKEN, state.token || '')
        // load last url path | MPAD-643
        const lastUrl = sessionStorage.getItem(EnumsDomStorage.ORIGIN_URL_PATH)
        return next(lastUrl ?? to.path)
      },
      async (error: ApiError) => {
        console.log('[B2C] /auth check error', error)
        if (!appStore.state.apiError) {
          await appStore.setApiError(
            error.code ?? FrontendErrorCode.AUTH_ERROR,
            error.message,
          )
        }
        return next()
      },
    )
  }

  //## Route call to route without authorization needed (like privacy policy) -> No Token needed
  if (to.meta.allowUnauthorized) {
    appStore.setDisableNavbar(true)
    appStore.toggleInitialLoading()
    return next()
  }

  /** validation of token through apiService **/
  if (!state.token && !appStore.state.apiError) {
    // load last url path | MPAD-643
    sessionStorage.setItem(EnumsDomStorage.ORIGIN_URL_PATH, to.fullPath)
    sessionStorage.setItem(
      EnumsDomStorage.ORIGIN_URL_QUERIES,
      JSON.stringify({ time: new Date(), queries: to.query }),
    )
    await appStore.redirectToLogin()
    return false
  }

  if (
    !appStore.state.initialLoading &&
    to.meta.onboarding &&
    to.name !== to.meta.onboarding &&
    !accountStore.getMetadataValue(MetadataKey.ONBOARDING_DONE)
  ) {
    // redirect to onboardingpage of this unit
    return next({ name: to.meta.onboarding as string })
  }

  // disable navbar if in route meta
  appStore.setDisableNavbar(!!to.meta.noNav)

  return next()
}

const logoutUser = () => {
  trackEvent('LogoutClick', 'logout')
  // TODO: Logout in backend
  localStorage.clear()
  const logoutUrl = appStore.state.isB2b
    ? `${import.meta.env.VITE_AUTH0_LOGOUT_URL}${appStore.state.baseUrl}`
    : `${import.meta.env.VITE_AUTH0_B2C_LOGOUT_URL}${appStore.state.baseUrl}`
  console.log('logoutUser with redirect', logoutUrl)
  window.location.replace(logoutUrl)
}

// Export complete store at once
export default { state: readonly(state), checkRouteAuth, logoutUser }
