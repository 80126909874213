import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'
import { version } from '../../package.json'
import { App } from 'vue'

const init = (app: App) => {
  Sentry.init({
    app,
    dsn: 'https://94cf3fac3a93410799fd6dea1cf44d75@o299618.ingest.sentry.io/5642903',
    integrations: [
      // new Integrations.BrowserTracing()
    ],
    logErrors: true,
    debug: import.meta.env.VITE_MODE === 'local',
    environment: import.meta.env.VITE_MODE as string,
    release: `${import.meta.env.VITE_MODE}@${version}`,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    // ignore errors like:
    // - user fetches resources and then reload the browser so the fetch interrupts
    // - after deployment of ne version, the cached files in a browser not available anymore
    ignoreErrors: [
      'NetworkError when attempting to fetch resource.',
      'Load failed',
      'Importing a module script failed.',
    ],
  })
}

const logException = (exception: any) => {
  Sentry.captureException(exception)
}

const logMessage = (message: string) => {
  Sentry.captureMessage(message)
}

export default { init, logException, logMessage }
