import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import generalRoutes from '@/router/routes/general'
import authStore from '@/stores/auth.store'
import appStore from '@/stores/app.store'
import { trackView } from '@/services/gtm.service'
import { suppressUsercentricsConsentModal } from '@/services/userhelper.service'

const routeFiles = import.meta.glob('/**/routes.ts', { eager: true })

// Object.keys(routeFiles).forEach(key => {
//     console.log('key', key)
//     if(routeFiles[key].default[0]) {
//         console.log('routeFiles[key].default[0]', routeFiles[key].default[0])
//         unitRoutes.push(routeFiles[key].default[0])
//     }
// })

const unitRoutes: RouteRecordRaw[] = []

if (appStore.state.isB2b) {
  // B2B routes
  // @ts-ignore
  unitRoutes.push(routeFiles['/src/units/b2b/routes.ts'].default[0])
} else {
  // B2C routes (DualesStudium)
  // @ts-ignore
  unitRoutes.push(routeFiles['/src/units/ds/routes.ts'].default[0])
}

const router = createRouter({
  history: createWebHistory(),
  // @ts-ignore
  routes: [
    ...unitRoutes,
    ...generalRoutes, // generalRoutes must be last in Array for menu-generator
  ],
  // scrollBehavior(to) {
  //     return new Promise((resolve) => {
  //         if (to.hash) {
  //             setTimeout(() => {
  //                 resolve({
  //                     el: to.hash,
  //                     top: 110, // header scroll
  //                     behavior: 'smooth'
  //                 })
  //             }, 800)
  //         }
  //     })
  // },
})

router.beforeEach((to, from, next) => {
  appStore.hideNavbar(true)
  return authStore.checkRouteAuth(to, from, next)
})

router.afterEach((to, from) => {
  appStore.setLastRouteName(from.name as string)
  trackView(to.name, to.path, from)
  setTimeout(() => {
    appStore.setShowBackToTop(false)
  }, 150)
  if (to.meta.suppressCmp) {
    suppressUsercentricsConsentModal()
  }
})

export default router
